import styled from "styled-components";

export const MenuButton = styled.a`
  transition: 0.5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 50px;
  :hover {
    background-color: rgba(255, 255, 255, 0.9);
    color: black;
  }
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.9);
`;

export const MenuContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
  height: 100%;
`;

export const HappyHourContainer = styled.div`
  display: flex;
  width: 100%;
  white-space: nowrap;
`;
