import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const BackgroundVideo = styled.video`
  position: fixed;
  z-index: -1;
  height: auto;
  width: 100%;
  display: ${(props) => (props.isLoading ? "none" : "inherit")};
`;

const BackgroundImage = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  height: 100vh;
  width: 100vw;
  /* display: ${(props) => (props.isLoading ? "none" : "inherit")}; */
  background-image: url("/club1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default function Background({ size, isLoading, setIsLoading }) {
  // if on mobile
  if (size?.width < 769) {
    setIsLoading(false);
    return null;
  }
  // if on venue
  // if (currentLocation.pathname === "/venue")
  else {
    return <BackgroundImage />;
  }
  // else on tablet and not venue page
  // else {
  //   if (currentLocation.pathname === "/venue") {
  //     setIsLoading(false);
  //   }
  //   return (
  //     <BackgroundVideo
  //       ref={vidRef}
  //       autoPlay
  //       muted
  //       loop
  //       playsinline
  //       isLoading={isLoading}
  //       onCanPlayThrough={() => {
  //         setIsLoading(false);
  //       }}
  //     >
  //       <source type="video/mp4" src="./2407_INSTA_blank.mp4" />
  //     </BackgroundVideo>
  //   );
  // }
}
