import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import { DocumentRenderer } from "@keystone-6/document-renderer";

import { useQuery, gql } from "@apollo/client";
import styled from "styled-components";

import { Logo } from "../../styles/AppStyles";
import dayjs from "dayjs";

const Body = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 600px;
`;

const EmbedContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  height: 100%;
`;

const GET_EVENT = gql`
  query GetEvent($id: ID) {
    event(where: { id: $id }) {
      id
      title
      date
      image {
        url
      }
      type
      ResidentAdvisorEventID
      description {
        document
      }
    }
  }
`;

const ResponsiveIframe = styled.iframe`
  position: relative;
  height: 100%;
  padding: 1rem;
  margin: auto;
`;

const Image = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  max-width: 100%;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }
  opacity: ${(props) => (props.imageLoading ? 0 : 1)};
  transition: 1s;
`;

export default function EventsModal({
  selectedId,
  toggleIsOpen,
  isOpen,
  size,
}) {
  const { loading, error, data } = useQuery(GET_EVENT, {
    variables: { id: selectedId },
  });

  const [isEmbedLoading, setIsEmbedLoading] = useState(true);
  const [isEmbedError, setIsEmbedError] = useState(false);
  const [imageLoading, setIsImageLoading] = useState(true);

  return (
    <Modal
      toggle={(e) => {
        e.preventDefault();
        toggleIsOpen(!isOpen);
      }}
      isOpen={isOpen}
      centered
      fullscreen={true}
      size="xl"
    >
      {!data?.event?.ResidentAdvisorEventID && (
        <ModalHeader
          toggle={(e) => {
            e.preventDefault();
            toggleIsOpen(!isOpen);
          }}
        >
          {data?.event?.date && (
            <h3>{dayjs(data.event.date).format("dddd D MMM ~ HH:mm")}</h3>
          )}
          {data?.event?.title && <h3>{data.event.title}</h3>}
        </ModalHeader>
      )}

      {error && <p>An error occured :(</p>}
      {loading ? (
        <Body>
          <Logo src="SOUP_LOGO_BLACK.png" />
        </Body>
      ) : (
        <Body>
          {data?.event?.image?.url && size?.width > 769 && (
            <div
              style={{
                maxHeight: "400px",
                display: "flex",
              }}
            >
              <Image
                imageLoading={imageLoading}
                src={data.event.image.url}
                onClick={(e) => {
                  window.open(
                    `https://ra.co/events/${data?.event?.ResidentAdvisorEventID}`
                  );
                }}
                onLoad={(e) => {
                  setIsImageLoading(false);
                }}
              ></Image>
            </div>
          )}
          {data?.event?.ResidentAdvisorEventID && isEmbedLoading && (
            <Logo src="SOUP_LOGO_BLACK.png" />
          )}
          {data?.event?.ResidentAdvisorEventID && !isEmbedError && (
            <EmbedContainer>
              <ResponsiveIframe
                title="Ra Tickets"
                src={`https://ra.co/widget/event/${data?.event?.ResidentAdvisorEventID}?hideback=true`}
                frameBorder="0"
                width="100%"
                onLoad={() => {
                  setIsEmbedLoading(false);
                }}
                onError={() => {
                  setIsEmbedError(true);
                }}
              />
            </EmbedContainer>
          )}
          {isEmbedError && (
            <Button
              color="primary"
              onClick={(e) => {
                window.open(
                  `https://ra.co/events/${data?.event?.ResidentAdvisorEventID}`
                );
              }}
            >
              Open Resident Advisor Event
            </Button>
          )}
          {!data?.event?.ResidentAdvisorEventID && (
            <DocumentRenderer document={data?.event?.description?.document} />
          )}
        </Body>
      )}
      {!loading && (
        <ModalFooter>
          {data?.event?.ResidentAdvisorEventID && (
            <Button
              onClick={(e) => {
                window.open(
                  `https://ra.co/events/${data?.event?.ResidentAdvisorEventID}`
                );
              }}
              style={{ backgroundColor: "#6b96c2" }}
            >
              Open on Resident Advisor
            </Button>
          )}

          <Button
            onClick={(e) => {
              e.preventDefault();
              toggleIsOpen(!isOpen);
            }}
          >
            Back
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}
