import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styled from "styled-components";

import { device } from "../styles/device";

const Mailchimp = styled(MailchimpSubscribe)`
  div > input {
    padding: 10rem !important;
  }
  color: white;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${device.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 3fr;
  }
`;

const Container = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  line-height: 1rem;
`;

export default function Contact() {
  const postUrl = `https://gmail.us5.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  return (
    <>
      <Layout>
        <Container>
          <h2>Opening times</h2>
          <p style={{ lineHeight: "1rem" }}>Weds & Thurs ~ 12pm - 1am</p>
          <p style={{ lineHeight: "1rem" }}>Fri & Sat ~ 12pm - 4am</p>
          {/* <p style={{ lineHeight: "1rem" }}>Kitchen open until 9pm</p> */}
        </Container>
        <Container>
          <h2>Address</h2>
          <a
            href="http://maps.apple.com/?address=31+33+spear+street+manchester&t=m"
            target="_blank"
            rel="noreferrer"
          >
            <h3>SOUP, 31-33 Spear St</h3>
            <h3>Manchester, M1 1DF</h3>
            <h3 style={{ marginTop: "1rem" }}>
              E:{" "}
              <a href="mailto:info@soupmanchester.com">
                info@soupmanchester.com
              </a>
            </h3>
          </a>
        </Container>
        <Container>
          <h2>Mailing List</h2>
          <Mailchimp url={postUrl} />
        </Container>

        <iframe
          title="Google Maps Embed"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.1972030581846!2d-2.236843683379846!3d53.482806172181064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1b93575e343%3A0x46f1c96a19f4786b!2sSOUP!5e0!3m2!1sen!2suk!4v1626856597911!5m2!1sen!2suk"
          width="100%"
          height="450"
          style={{
            border: 0,
            marginTop: "1rem",
            gridArea: "2/1/3/4",
            marginBottom: "1rem",
          }}
          allowfullscreen=""
          loading="lazy"
        ></iframe>
        {/* <Container style={{ gridArea: "2/3/3/4" }}>
          <h2>
            Getting In Touch For External Events{" "}
            <span style={{ fontSize: "0.75rem" }}>(live)</span>
          </h2>
          <li style={{ marginBottom: "1.5rem", marginTop: "1.5rem" }}>
            <a href="https://docs.google.com/forms/d/1AEs8jARL5013YEzwZjt21Y8nUdYsRJ22acocP76ryIs/edit">
              Independent promoters
            </a>
          </li>
          <li>
            <a href="https://docs.google.com/forms/d/1y8HbyNwg_eQt2WKRkfmntappVOikokjFx3IuNoZN_5M/edit">
              National promoters
            </a>
          </li>
        </Container> */}
      </Layout>
    </>
  );
}
