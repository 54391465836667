import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { useQuery, gql } from "@apollo/client";
import { EventCard } from "./EventCard";
import EventsModal from "./EventsModal";
import { device } from "../../styles/device";
import { Logo } from "../../styles/AppStyles";

import EventsCollapsible from "./EventsCollapsible";

const ColumnContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Column = styled.div`
  padding: 1rem;
`;

const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const TypeTitle = styled.h2`
  color: black;
  border-radius: 10px;
  font-weight: normal;
`;

const GET_EVENTS = gql`
  query GetEvents {
    events {
      title
      date
      type
      id
      image {
        url
      }
    }
  }
`;

export default function Events({ size }) {
  const { loading, error, data, refetch } = useQuery(GET_EVENTS);
  const [clubEvents, setClubEvents] = useState([]);
  const [gigEvents, setGigEvents] = useState([]);
  const [isOpen, toggleIsOpen] = useState(false);

  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    if (selectedId !== "") {
      toggleIsOpen(true);
    }
  }, [selectedId]);

  useEffect(() => {
    if (data) {
      const today = new Date();
      let futureEvents = data.events
        .filter((event) => {
          const eventDate = new Date(event.date);
          if (today < eventDate) {
            return true;
          } else return false;
        })
        .sort((eventA, eventB) => {
          return new Date(eventA.date) - new Date(eventB.date);
        });
      let recievedClubEvents = futureEvents.filter(
        (event) => event.type === "club"
      );
      let recievedGigEvents = futureEvents.filter(
        (event) => event.type === "gig"
      );
      setClubEvents(recievedClubEvents);
      setGigEvents(recievedGigEvents);
    }
  }, [data]);

  if (error) {
    refetch();
    return <p>Error loading events :(</p>;
  } else if (loading) {
    return (
      <div>
        <Logo src="SOUP_LOGO_BLACK.png" />
      </div>
    );
  } else
    return (
      <ColumnContainer>
        <Column>
          <EventsCollapsible header="Live" open>
            {gigEvents &&
              gigEvents.length > 0 &&
              gigEvents.map((event) => {
                return (
                  <EventCard
                    date={event.date}
                    title={event.title}
                    type={event.type}
                    setSelectedId={setSelectedId}
                    id={event.id}
                    key={event.id}
                    toggleIsOpen={toggleIsOpen}
                    image={event.image}
                  />
                );
              })}
          </EventsCollapsible>
        </Column>
        <Column>
          <EventsCollapsible header="Club" open>
            {clubEvents &&
              clubEvents.length > 0 &&
              clubEvents.map((event) => {
                return (
                  <EventCard
                    date={event.date}
                    title={event.title}
                    type={event.type}
                    setSelectedId={setSelectedId}
                    id={event.id}
                    key={event.id}
                    toggleIsOpen={toggleIsOpen}
                    image={event.image}
                  />
                );
              })}
          </EventsCollapsible>
        </Column>
        <EventsModal
          isOpen={isOpen}
          toggleIsOpen={toggleIsOpen}
          selectedId={selectedId}
          size={size}
        />
      </ColumnContainer>
    );
}
