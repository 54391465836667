import React from "react";

import styled from "styled-components";
import ImageGallery from "react-image-gallery";

const AccessContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
`;

const AccessImage = styled.img`
  width: auto;
  margin: 1rem;
  height: 100px;
`;

export default function Venue() {
  return (
    <React.Fragment>
      <AccessContainer>
        <AccessImage
          alt="soup logo"
          style={{ margin: "auto" }}
          src="SOUP_LOGO_BLACK.png"
        />
      </AccessContainer>
      <p className="tac mb1">
        A gig & club space in the heart of Manchester’s northern quarter
        programming forward thinking music and arts events, pulling deeply from
        our local area as well as artists from further afield.
      </p>
      <p className="tac mb1">
        Our new booking policy aims to make our events more financially
        accessible,we currently cap ticket prices for most Internal club events
        at £10, but always try to offer a selection of cheaper tickets from £5,
        with higher ticket prices reserved for nights with extended opening
        hours or international artists.
      </p>
      <p className="tac mb1">
        At SOUP we work to be a safer space venue by implementing zero tolerance
        policies and training our staff in helping victims of abuse & harassment
        with the Good Night Out Campaign.
      </p>
      <p className="tac mb1">
        We are accredited by Attitude is Everything, a charity that works to
        facilitate better live music spaces for deaf people and people with
        disabilities. Our staff are trained to meet the needs of all of our
        customers and we are working constantly to improve our venue’s
        accessibility.
      </p>
      <AccessContainer>
        <AccessImage src="goodnightout.png" alt="Good Night Out Logo" />
        <AccessImage
          src="attitudeiseverything.png"
          alt="Attitude Is Everything Logo"
        />
      </AccessContainer>
      <p className="tac uppercase bold mb1">Accessibility</p>
      <p className="tac bold mb1">
        Unfortunately due to necessary maintenance and repair works to our
        buildings lift which are taking place between{" "}
        <span className="underline">21.07.23 - 01.09.23</span> we cannot offer
        accessible entrance to the basement from ground floor.
      </p>
      <ul className=" mb1 ">
        <li>We offer free personal assistance tickets</li>
        {/* <li>
          Lift access to the venue is available, please contact us to organise
          access ahead of the event
        </li> */}
        <li>Disabled toilets on both floors</li>
        <li>Accessible parking</li>
        <li>
          For free personal assistance tickets, to organise lift access and for
          all other accessibility inquiries please get in touch with us at{" "}
          <a href="mailto:tickets@soupmanchester.com">
            tickets@soupmanchester.com
          </a>
        </li>
      </ul>
      <ImageGallery
        slideInterval={6000}
        slideDuration={300}
        showPlayButton={false}
        showNav={false}
        showFullscreenButton={false}
        autoPlay={true}
        items={[
          { original: "venue1.jpg" },
          { original: "live1.jpg" },
          { original: "club1.jpg" },
          { original: "club2.jpg" },
        ]}
      />
    </React.Fragment>
  );
}
