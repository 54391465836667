import React from "react";
import styled from "styled-components";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const FooterContainer = styled.footer`
  height: 4rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  margin-top: 1rem;
  outline: 1px solid black;
  justify-content: space-evenly;
  > * {
    flex-grow: 1;
    border-right: 1px solid black;
    color: black;
    display: flex;
    transition: 0.3s;
    &:hover:not(:last-child) {
      background-color: black;
      // color: white;
      color: white;
    }
  }
`;

const FooterIcon = styled(FontAwesomeIcon)`
  margin: auto;
  font-size: 1.5rem;
`;

const LogoLink = styled(Link)`
  // logo causing errors on firefox vs chromew
  object-fit: contain;
  height: 100%;
  border: none;
  outline: none;
  margin: auto;
  flex-grow: 1;
  flex-shrink: 0;
  &:hover {
    background-color: black;
  }
`;

const LogoImg = styled.img`
  object-fit: contain;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  &:hover {
    filter: invert(1);
  }
`;

export default function Footer() {
  return (
    <FooterContainer>
      <a
        target="_blank"
        href="https://www.facebook.com/soupmanchester/"
        rel="noreferrer"
        aria-label="Facebook Link"
      >
        <FooterIcon icon={faFacebook}></FooterIcon>
      </a>
      <a
        target="_blank"
        href="https://twitter.com/soupmanchester"
        rel="noreferrer"
        aria-label="Twitter Link"
      >
        <FooterIcon icon={faTwitter}></FooterIcon>
      </a>
      <a
        target="_blank"
        href="https://www.instagram.com/soup_manchester/"
        rel="noreferrer"
        aria-label="Instagram Link"
      >
        <FooterIcon icon={faInstagram}></FooterIcon>
      </a>
      <a
        target="_blank"
        href="mailto:info@soupmanchester.com"
        rel="noreferrer"
        aria-label="Email Link"
      >
        <FooterIcon icon={faEnvelope}></FooterIcon>
      </a>
      <LogoLink to={"/"} aria-label="Back to homepage link">
        <LogoImg alt="Soup Logo" src="./SOUP_LOGO_BLACK.png"></LogoImg>
      </LogoLink>
    </FooterContainer>
  );
}
