import React from "react";

import styled from "styled-components";

import { Link, useLocation } from "react-router-dom";

const NavBarContainer = styled.nav`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  height: 5rem;
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;
  flex-shrink: 0;
  outline: 1px solid black;
  > * {
    flex-grow: 1;
    outline: 1px solid black;
    text-transform: uppercase;
    color: ${(props) => (props.isactive ? "white" : "black")};
    background-color: ${(props) => (props.isactive ? "black" : "white")};
    text-align: center;
    display: flex;
    text-decoration: none;
    &:hover {
      background-color: ${(props) => !props.isactive && "#808080"};
      color: white;
    }
  }
`;

const LinkText = styled.span`
  margin: auto;
`;

const LinkContainer = styled(Link)`
  color: ${(props) => (props.isactive ? "white" : "black")};
  background-color: ${(props) => (props.isactive ? "black" : "white")};
  transition: 0.5s;
`;

export default function Navbar() {
  const location = useLocation();
  return (
    <NavBarContainer>
      {/* <LinkContainer
        to={location.pathname === "/bar" ? "/" : "/bar"}
        isactive={location.pathname === "/bar" ? 1 : 0}
      >
        <LinkText>Kitchen</LinkText>
      </LinkContainer> */}
      <LinkContainer
        to={location.pathname === "/venue" ? "/" : "/venue"}
        isactive={location.pathname === "/venue" ? 1 : 0}
      >
        <LinkText>Venue</LinkText>
      </LinkContainer>
      <LinkContainer
        to={location.pathname === "/events" ? "/" : "/events"}
        isactive={location.pathname === "/events" ? 1 : 0}
      >
        <LinkText>Events</LinkText>
      </LinkContainer>
      <LinkContainer
        to={location.pathname === "/about" ? "/" : "/about"}
        isactive={location.pathname === "/about" ? 1 : 0}
      >
        <LinkText>Contact</LinkText>
      </LinkContainer>
    </NavBarContainer>
  );
}
