import React, { useState, useEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import ImageGallery from "react-image-gallery";

import Navbar from "./components/Navbar/Navbar";
import Contact from "./components/Contact";
import Events from "./components/Events/Events";
import {
  Grid,
  Main,
  MainContentContainer,
  ContentContainer,
} from "./styles/AppStyles";
import Kitchen from "./components/Kitchen/Kitchen";
import Loading from "./components/Loading/Loading";
import Venue from "./components/Venue/Venue";
import Footer from "./components/Footer/Footer";

import { CSSTransition, TransitionGroup } from "react-transition-group";
import Background from "./components/Background";

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_ADDRESS,
  cache: new InMemoryCache(),
  include: "include",
});

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const size = useWindowSize();

  return (
    <BrowserRouter>
      <Grid>
        {isLoading ? (
          <Loading />
        ) : (
          <MainContentContainer>
            <Navbar />
            <Main>
              <Route
                render={({ location }) => {
                  return (
                    <TransitionGroup component={null}>
                      <CSSTransition
                        key={location.key}
                        timeout={450}
                        classNames="fade"
                      >
                        <Switch>
                          <Route exact path="/">
                            <ContentContainer>
                              <ImageGallery
                                slideInterval={6000}
                                slideDuration={300}
                                showPlayButton={false}
                                showNav={false}
                                showFullscreenButton={false}
                                autoPlay={true}
                                items={[
                                  { original: "souphome1.jpg" },
                                  { original: "souphome2.jpg" },
                                ]}
                              />
                              <div style={{ marginBottom: "1rem" }}></div>
                              <ImageGallery
                                slideInterval={6000}
                                slideDuration={300}
                                showPlayButton={false}
                                showNav={false}
                                showFullscreenButton={false}
                                autoPlay={true}
                                items={[
                                  { original: "souphome3.jpg" },
                                  { original: "souphome4.jpg" },
                                  { original: "souphome6.jpg" },
                                ]}
                              />
                            </ContentContainer>
                          </Route>
                          <Route exact path="/events">
                            <ApolloProvider client={client}>
                              <ContentContainer>
                                <Events size={size} />
                              </ContentContainer>
                            </ApolloProvider>
                          </Route>
                          <Route path="/bar">
                            <ContentContainer>
                              <Kitchen />
                            </ContentContainer>
                          </Route>
                          <Route path="/venue">
                            <ContentContainer>
                              <Venue />
                            </ContentContainer>
                          </Route>
                          <Route path="/about">
                            <ContentContainer>
                              <Contact />
                            </ContentContainer>
                          </Route>
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  );
                }}
              />
            </Main>
            <Footer />
          </MainContentContainer>
        )}

        <Background
          size={size}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Grid>
    </BrowserRouter>
  );
}

export default App;
