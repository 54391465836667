import React from "react";

import styled from "styled-components";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Background = styled.div`
  position: fixed;
  background-color: black;
  height: 100vh;
  width: 100vw;
`;

const Logo = styled.img`
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-name: spin;
  transform-origin: 50% 50%;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes spin {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

const ProgressProvider = ({ valueStart, valueEnd, children }) => {
  const [value, setValue] = React.useState(valueStart);
  React.useEffect(() => {
    if (value < valueEnd) {
      setTimeout(() => {
        setValue(value + 1);
      }, 45);
    }
  });

  return children(value);
};

export default function Loading() {
  return (
    <div>
      <Background>
        <Logo src="SOUP_LOGO_WHITE.png" />
      </Background>
    </div>
  );
}
