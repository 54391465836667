import React from "react";

import { MenuButton, MenuContainer } from "./styles";
import { Image } from "../../styles/AppStyles";

export default function Kitchen() {
  return (
    <React.Fragment>
      <MenuContainer>
        <picture
          style={{ height: "97%" }}
          // loading="lazy"
        >
          <source srcset="/food1.webp" type="image/webp"></source>
          <source srcset="/food1.jpg" type="image/jpeg" />
          <Image src="/food1.jpg" alt="Food" />
        </picture>
        <MenuButton
          target="_blank"
          href="https://soup-menu.s3.eu-west-2.amazonaws.com/MENU+2022+TO+PRINT+LARGE+FORMAT.pdf"
          rel="noreferrer"
        >
          View Menu
        </MenuButton>
      </MenuContainer>
      {/* <HappyHourContainer>
        <div style={{ flexGrow: 1 }}>
          <Image loading="lazy" src="./food1.webp"></Image>
        </div>
        <div
          style={{
            textAlign: "right",
            marginLeft: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <h2>Happy hour</h2>
          <p>12 - 7pm </p>
          <p>Wednesday to Friday</p>
          <p>All day Sunday </p>
          <br></br>
          <p>£3.50 Pints</p>
          <p>£3.50 Spirit And Mixer</p>
          <p>£5 Cocktails </p>
          <p>£15 Bottle Wine </p>
          <p>£20 Bottle Prosecco</p>
          <br></br>
        </div>
      </HappyHourContainer> */}
    </React.Fragment>
  );
}
