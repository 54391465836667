import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

import dayjs from "dayjs";

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const OuterContainer = styled.div`
  margin-top: 1rem;
  &:hover {
    transform: translate(0px, -3px);
    box-shadow: "0 8px 10px rgba(1, 0, 1, 0.1), 0 14px 20px rgba(1, 1, 1, 0.3)";
  }
  transition: 200ms;
`;

const CardContainer = styled.div`
  /* border-radius: 8px; */
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: ${(props) => (props.hasImg ? "0px" : "5px")};
  border-top-right-radius: ${(props) => (props.hasImg ? "0px" : "5px")};

  margin: auto;
  margin-top: 0rem;
  display: grid;
  grid-template-columns: 1fr 40px;
  background-color: #494949;
  box-shadow: "0 3px 5px rgba(1, 0, 1, 0.05), 0 7px 10px rgba(1, 1, 1, 0.2)";
  color: rgba(245, 245, 245, 1);
  transition: 200ms;
`;

const TextAndIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: "offwhite";
`;

const CardText = styled.h3`
  margin: 0.75rem;
  color: rgba(245, 245, 245, 1);
`;

const EventButton = styled.button`
  transition: 200ms;

  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  background: rgba(107, 107, 107, 0.8);
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 100%;
  border-radius: 0%;
  /* border-top-right-radius: 5px; */
  border-bottom-right-radius: 5px;
  border-top-right-radius: ${(props) => (props.hasImg ? "0px" : "5px")};
  &:hover {
    background-color: rgba(107, 107, 107, 1);
  }
  color: rgba(245, 245, 245, 1);
`;

export const EventCard = ({
  title,
  date,
  id,
  setSelectedId,
  type,
  toggleIsOpen,
  image,
}) => {
  return (
    <OuterContainer style={{ width: "100%" }}>
      {image && image.url && <Image src={image.url} />}
      <CardContainer hasImg={image && image.url ? true : false} type={type}>
        <TextAndIconContainer>
          <CardText>
            <div
              style={{
                marginBottom: "0.5rem",
                borderBottom: "1px solid rgba(245, 245, 245, 1)",
              }}
            >
              <b style={{ color: "rgba(245, 245, 245, 1)" }}>
                {dayjs(date).format("dddd D MMM ~ HH:mm")}
              </b>
            </div>
            <div style={{ color: "rgba(245, 245, 245, 1)" }}>
              <h3>{title}</h3>
            </div>
          </CardText>
        </TextAndIconContainer>
        <TextAndIconContainer>
          <EventButton
            type={type}
            hasImg={image && image.url ? true : false}
            onClick={(e) => {
              e.preventDefault();
              setSelectedId(id);
              toggleIsOpen(true);
            }}
          >
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              style={{ color: "offwhite" }}
            />
          </EventButton>
        </TextAndIconContainer>
      </CardContainer>
    </OuterContainer>
  );
};
